import React, { Component } from "react"
import { FiCast, FiLayers, FiUsers, FiMonitor } from "react-icons/fi"

const ServiceList = [
  {
    icon: <FiCast />,
    title: "Request",
    description:
      "Send me your current business information, website, logo and ideas.",
  },
  {
    icon: <FiLayers />,
    title: "Idea",
    description:
      "I will respond with some ideas to choose from, using your information.",
  },
  {
    icon: <FiUsers />,
    title: "Marketing & Reporting",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiMonitor />,
    title: "Mobile App Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiUsers />,
    title: "Marketing & Reporting",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
  {
    icon: <FiMonitor />,
    title: "Mobile App Development",
    description:
      "I throw myself down among the tall grass by the stream as I lie close to the earth.",
  },
]

class ServiceThree extends Component {
  render() {
    const { column } = this.props
    const ServiceContent = ServiceList.slice(0, this.props.item)

    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <a href="/service-details">
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
      </React.Fragment>
    )
  }
}
export default ServiceThree
