// React Required
import React, { Component } from "react"
import ReactDOM from "react-dom"
import PageScrollTop from "./component/PageScrollTop"
// Create Import File
import "./index.scss"

import Home from "./home"

import { BrowserRouter, Switch, Route } from "react-router-dom"
import * as serviceWorker from "./serviceWorker"

class Root extends Component {
  render() {
    return (
      <BrowserRouter basename={"/"}>
        <PageScrollTop>
          <Switch>
            <Route exact path={`/`} component={Home} />
          </Switch>
        </PageScrollTop>
      </BrowserRouter>
    )
  }
}

ReactDOM.render(<Root />, document.getElementById("root"))
serviceWorker.register()
