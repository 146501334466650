import React, { Component } from "react";
import {Link} from "react-router-dom";

const PortfolioListContent = [
    {
        image: 'image-1',
        category: 'Development',
        title: 'Sistema de doações de bolsas de estudo',
        link: 'https://graduacao.anapolis.go.gov.br/'
    },
    {
        image: 'image-2',
        category: 'Development',
        title: 'Meu Lote Minha História',
        link: 'https://meuloteminhahistoria.anapolis.go.gov.br/'
    },
    {
        image: 'image-3',
        category: 'Development',
        title: 'Sistema de controle de doações',
        link: 'https://doacoes.anapolis.go.gov.br/'
    },
    {
        image: 'image-4',
        category: 'Development',
        title: 'Boletim de acidentes de trânsito',
        link: 'http://www.servicosweb.anapolis.go.gov.br:8103/bat/index.jsf'
    },
    {
        image: 'image-5',
        category: 'Development',
        title: 'Portal da transparência do Municipio de Anápolis',
        link: 'https://www.anapolis.go.leg.br/transparencia'
    },
    {
        image: 'image-6',
        category: 'Development',
        title: 'Sistema de doações de bolsas de estudo',
        link: 'https://dom22.anapolis.go.gov.br/'
    }
]

class PortfolioList extends Component{
    render(){
        const {column , styevariation } = this.props;
        const list = PortfolioListContent.slice(0 , this.props.item);
        return(
            <React.Fragment> 
                {list.map((value , index) => (
                    <div className={`${column}`} key={index}>
                        <div className={`portfolio ${styevariation}`}>
                            <div className="thumbnail-inner">
                                <div className={`thumbnail ${value.image}`}></div>
                                <div className={`bg-blr-image ${value.image}`}></div>
                            </div>
                            <div className="content">
                                <div className="inner">
                                    <p>{value.category}</p>
                                    <h4><a href="/portfolio-details">{value.title}</a></h4>
                                    <div className="portfolio-button">
                                        <a className="rn-btn" href={value.link} target="_blank" rel="noreferrer">Veja detalhes</a>
                                    </div>
                                </div>
                            </div>
                            <Link className="link-overlay" to="/portfolio-details"></Link>
                        </div>
                    </div>
                ))}
               
            </React.Fragment>
        )
    }
}
export default PortfolioList;