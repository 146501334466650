import React, { Component } from "react"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"

class TabsTwo extends Component {
  render() {
    let tab1 = "Visão",
      tab3 = "Missão",
      tab4 = "Valores"
    const { tabStyle } = this.props
    return (
      <div>
        {/* Start Tabs Area */}
        <div className="tabs-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <Tabs>
                  <TabList className={`${tabStyle}`}>
                    <Tab>{tab1}</Tab>
                    {/* <Tab>{tab2}</Tab> */}
                    <Tab>{tab3}</Tab>
                    <Tab>{tab4}</Tab>
                  </TabList>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a href="/service">
                          Excelência no desenvolvimento de websites <span> </span>
                          </a>
                          Buscamos constantemente a excelência em todas as etapas do desenvolvimento de websites.
                        </li>
                        <li>
                          <a href="/service">Reconhecimento como líder no setor</a>
                          Almejamos ser reconhecidos como líderes no mercado de desenvolvimento de websites.
                        </li>
                        <li>
                          <a href="/service">
                          Oferecer soluções inovadoras e personalizadas <span> </span>
                          </a> Criamos soluções criativas e personalizadas para cada cliente.
                        </li>
                        <li>
                          <a href="/service">
                          Auxiliar nossos clientes a alcançar seus objetivos online <span> </span>
                          </a>Estamos comprometidos em ajudar nossos clientes a ter sucesso online.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a href="/service">
                          Desenvolvimento de websites personalizados e exclusivos
                          </a>{" "}
                          Trabalhamos em estreita colaboração com cada cliente para criar websites que reflitam sua identidade e objetivos.
                        </li>
                        <li>
                          <a href="/service">
                          Incorporação de ideias criativas e inovadoras
                          </a>{" "}
                          Buscamos constantemente ideias criativas e inovadoras para criar websites únicos.
                        </li>
                        <li>
                          <a href="/service">
                          Foco na funcionalidade e experiência do usuário
                          </a>{" "}
                          Priorizamos a funcionalidade e a experiência do usuário em todos os projetos.
                        </li>
                        <li>
                          <a href="/service">
                          Colaboração estreita com os clientes para entender suas necessidades
                          </a>{" "}
                          Valorizamos a colaboração com nossos clientes para atender às suas necessidades específicas.
                        </li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="single-tab-content">
                      <ul>
                        <li>
                          <a href="/service">
                          Excelência
                          </a>{" "}
                          • Busca constante pela qualidade <br/>
                          • Aprimoramento de habilidades e conhecimentos técnicos <br/>
                          • Superar as expectativas dos clientes
                        </li>
                        <li>
                          <a href="/service">
                          Criatividade
                          </a>{" "}
                          • Soluções criativas e inovadoras <br/>
                          • Design único e envolvente <br/>
                          • Destacar-se no ambiente online
                        </li>
                        <li>
                          <a href="/service">
                          Integridade
                          </a>{" "}
                          • Agir com ética e transparência <br/>
                          • Honestidade em todas as interações <br/>
                          • Respeito aos clientes, parceiros e membros da equipe
                        </li>
                      </ul>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        {/* End Tabs Area */}
      </div>
    )
  }
}

export default TabsTwo
