import React from "react"
import ScrollToTop from "react-scroll-up"
import { FiChevronUp, FiCheck } from "react-icons/fi"
import Helmet from "./component/common/Helmet"
import TextLoop from "react-text-loop"
import HeaderThree from "./component/header/HeaderThree"
import FooterTwo from "./component/footer/FooterTwo"
import TabTwo from "./elements/tab/TabTwo"
import ContactThree from "./elements/contact/ContactThree"
import PortfolioList from "./elements/portfolio/PortfolioList"
import ServiceList from "./elements/service/ServiceList"
import BlogContent from "./elements/blog/BlogContent"

const SlideList = [
  {
    textPosition: "text-left",
    category: "Bem-vindos à Idealizou!",
    description: "",
    buttonText: "",
    buttonLink: "",
  },
]
const PortfolioLanding = () => {
  let title = "Quem somos",
    description =
      "Empresa especializada em desenvolvimento de websites, focada em criar experiências digitais excepcionais para impulsionar o sucesso dos nossos clientes."
  const PostList = BlogContent.slice(0, 3)
  return (
    <div className="active-dark">
      <Helmet pageTitle="Idealizou" />

      <HeaderThree homeLink="/" logo="symbol-dark" color="color-black" />
      {/* Start Slider Area   */}
      <div id="home" className="fix">
        <div className="slider-wrapper">
          {/* Start Single Slide */}
          {SlideList.map((value, index) => (
            <div
              className="slide personal-portfolio-slider slider-paralax slider-style-3 d-flex align-items-center justify-content-center bg_image bg_image--25"
              key={index}
            >
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className={`inner ${value.textPosition}`}>
                      {value.category ? <span>{value.category}</span> : ""}
                      <h1 className="title">
                        Desenvolvimento web personalizado
                        <br />
                        <TextLoop>
                          <span> Websites.</span>
                          <span> Ecommerce.</span>
                          <span> Sistemas de gestão.</span>
                        </TextLoop>{" "}
                      </h1>
                      <h2>sede no Brasil.</h2>
                      {value.description ? (
                        <p className="description">{value.description}</p>
                      ) : (
                        ""
                      )}
                      {value.buttonText ? (
                        <div className="slide-btn">
                          <a
                            className="rn-button-style--2 btn-primary-color"
                            href={`${value.buttonLink}`}
                          >
                            {value.buttonText}
                          </a>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* End Single Slide */}
        </div>
      </div>
      {/* End Slider Area   */}

      {/* Start About Area */}
      <div id="about" className="fix">
        <div className="about-area ptb--120  bg_color--1">
          <div className="about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-8.jpeg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                    <div className="row mt--30">
                      <TabTwo tabStyle="tab-style--1" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* End About Area */}

                 {/* Start Pricing Tbale Area  */}
                 <div id="service" className="rn-pricing-table-area ptb--120 bg_color--5">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="section-title service-style--3 text-center mb--25 mb_sm--0">
                                    <h2 className="title">Serviços</h2>
                                    <p>Se você está buscando desenvolver um site ou uma loja virtual, é importante 
                                      considerar as opções de pacotes disponíveis para escolher a que melhor se adequa 
                                      às suas necessidades e orçamento. A seguir, temos três opções de pacotes 
                                      para desenvolvimento web:</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Básico</h4>
                                            <div className="pricing">
                                                <span className="price">499</span>
                                                <span className="subtitle">R$ à vista</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Até 5 páginas (home, sobre nós, contato)</li>
                                                <li><FiCheck /> Design responsivo (amigável para navegação mobile)</li>
                                                <li><FiCheck /> Botão Flutuante Whatsapp</li>
                                                <li><FiCheck /> Site Seguro (com https://)</li>
                                                <li><FiCheck /> Link para suas redes sociais</li>
                                                <li><FiCheck /> Manutenção e servidor por R$50 mensais</li>
                                                <li><FiCheck /> Entrega em até 5 dias úteis</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Comprar Agora</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing active">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">Empresárial + manutenção</h4>
                                            <div className="pricing">
                                                <span className="price">899</span>
                                                <span className="subtitle">R$ à vista</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">                                            
                                                <li><FiCheck /> Até 10 páginas (home, sobre nós, contato)</li>
                                                <li><FiCheck /> Design responsivo (amigável para navegação mobile)</li>
                                                <li><FiCheck /> Botão Flutuante Whatsapp</li>
                                                <li><FiCheck /> Site Seguro (com https://)</li>
                                                <li><FiCheck /> Link para suas redes sociais</li>
                                                <li><FiCheck /> Manutenção e servidor por R$200 mensais</li>
                                                <li><FiCheck /> Entrega em até 10 dias úteis</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Compre Agora</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}

                            {/* Start PRicing Table Area  */}
                            <div className="col-lg-4 col-md-6 col-12 mt--30">
                                <div className="rn-pricing">
                                    <div className="pricing-table-inner">
                                        <div className="pricing-header">
                                            <h4 className="title">e-Commerce</h4>
                                            <div className="pricing">
                                                <span className="price">1999</span>
                                                <span className="subtitle">R$ à vista</span>
                                            </div>
                                        </div>
                                        <div className="pricing-body">
                                            <ul className="list-style--1">
                                                <li><FiCheck /> Design responsivo (amigável para navegação mobile)</li>
                                                <li><FiCheck /> Suporte e alterações de texto e imagens inclusas na mensalidade</li>
                                                <li><FiCheck /> Site Seguro (com https://)</li>
                                                <li><FiCheck /> Link para suas redes sociais</li>
                                                <li><FiCheck /> Manutenção e servidor por R$200 mensais</li>
                                                <li><FiCheck /> Consulte-nos para saber mais</li>
                                                <li><FiCheck /> Entrega à combinar com o cliente</li>
                                            </ul>
                                        </div>
                                        <div className="pricing-footer">
                                            <a className="rn-btn" href="#contact">Compre Agora</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* End PRicing Table Area  */}
                        </div>
                    </div>
                </div>
                {/* End Pricing Tbale Area  */}

      {/* Start Portfolio Area */}
      <div id="portfolio" className="fix">
        <div className="portfolio-area ptb--120 bg_color--1">
          <div className="portfolio-sacousel-inner">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                    <h2 className="title">Projetos mais recentes</h2>
                    <p>
                    Desde websites corporativos elegantes até plataformas de comércio eletrônico dinâmicas, 
                    nossos trabalhos demonstram a qualidade e a criatividade que oferecemos em nossas soluções 
                    de desenvolvimento de websites.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <PortfolioList
                  styevariation="text-center mt--40"
                  column="col-lg-4 col-md-6 col-sm-6 col-12"
                  item="6"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* End Portfolio Area */}

      {/* Start COntact Area */}
      <div id="contact" className="fix">
        <div className="rn-contact-area ptb--120 bg_color--1">
          <ContactThree
            contactImages="/assets/images/service/service-02.png"
            contactTitle="Entre em contato."
          />
        </div>
      </div>
      {/* End COntact Area */}

      <FooterTwo />
      {/* Start Back To Top */}
      <div className="backto-top">
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
      {/* End Back To Top */}
    </div>
  )
}

export default PortfolioLanding
