import React, { Component } from 'react';

class ContactThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }

    handleSendMessage = () => {
        const { rnName, rnEmail, rnSubject, rnMessage } = this.state;
        const message = `Nome: ${rnName}%0AEmail: ${rnEmail}%0AAssunto: ${rnSubject}%0AMensagem: ${rnMessage}`;
        const whatsappUrl = `https://wa.me/+5562998509956/?text=${rnMessage}`;
        window.open(whatsappUrl, '_blank');
    };

    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">{this.props.contactTitle}</h2>
                                <p className="description">
                                    Estamos ansiosos para ouvir você! Entre em contato conosco para obter mais informações sobre nossos serviços de desenvolvimento de websites personalizados.
                                    Nossos especialistas estão prontos para atendê-lo e discutir suas necessidades específicas.
                                </p>
                            </div>
                            <div className="form-wrapper">
                                <label htmlFor="item01">
                                    <input
                                        type="text"
                                        name="name"
                                        id="item01"
                                        value={this.state.rnName}
                                        onChange={(e) => {
                                            this.setState({ rnName: e.target.value });
                                        }}
                                        placeholder="Seu Nome *"
                                    />
                                </label>

                                <label htmlFor="item02">
                                    <input
                                        type="text"
                                        name="email"
                                        id="item02"
                                        value={this.state.rnEmail}
                                        onChange={(e) => {
                                            this.setState({ rnEmail: e.target.value });
                                        }}
                                        placeholder="Seu email *"
                                    />
                                </label>

                                <label htmlFor="item03">
                                    <input
                                        type="text"
                                        name="subject"
                                        id="item03"
                                        value={this.state.rnSubject}
                                        onChange={(e) => {
                                            this.setState({ rnSubject: e.target.value });
                                        }}
                                        placeholder="Digite o assunto"
                                    />
                                </label>
                                <label htmlFor="item04">
                                    <textarea
                                        type="text"
                                        id="item04"
                                        name="message"
                                        value={this.state.rnMessage}
                                        onChange={(e) => {
                                            this.setState({ rnMessage: e.target.value });
                                        }}
                                        placeholder="Sua mensagem"
                                    />
                                </label>
                                <button
                                    className="rn-button-style--2 btn-solid"
                                    type="button" // change type to button
                                    onClick={this.handleSendMessage} // add click handler
                                >
                                    Enviar
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src={`${this.props.contactImages}`} alt="trydo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ContactThree;
